import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './appetini.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import Blockquote from '../components/blockquote';
import { Routes } from '../../routes';
// other
import { meta } from '../../../metaData';

const AppetiniCase = () => {
  const data = useStaticQuery(graphql`
    query {
      rodionSalnik: file(relativePath: { eq: "rodion-salnik.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "appetini-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "appetini-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "appetini-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "appetini-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "appetini-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      score={['Foodtech', 'Sumy, Ukraine', 'December, 2017', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Appetini Custom&nbsp;— Designed Meal Delivery Solution"
      subTitle="Take a sneak peek at how we developed a food delivery service platform to cover our needs when ordering lunches for offices in our city"
      metaData={meta.appetini}
    >
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          Trying to catch up with the ever-shifting lunch delivery services, our founder{' '}
          <a href="https://www.linkedin.com/in/rodik/" target="__blank" className={styles.link}>
            Rodion Salnik
          </a>{' '}
          suggested an idea to develop a feature-rich food ordering app that was later adopted by
          the <b>entire team</b>. In 2016, it was the right call for us since most of the big
          players haven't entered the Sumy market yet. That's why we wanted to come into play and
          build a product for our town.
        </p>
        <p className={itemStyles.paragraph}>
          It was a food delivery startup project that we prepared inspired by the accelerators we
          had taken part in beforehand. Speaking of which, you might want to consider the mistakes
          that we made while building Appetini and find some helpful hints in our latest article{' '}
          <Link
            to={Routes.BLOG + 'how-to-not-fail-your-food-delivery-startup/'}
            className={styles.link}
          >
            How to Not Fail Your Food Delivery Startup: Crucial Factors to Consider
          </Link>
          .
        </p>
        <p className={itemStyles.paragraph}>
          Backed by our previous expertise in the{' '}
          <Link to={Routes.INDUSTRIES_FOODTECH} className={styles.link}>
            Foodtech development
          </Link>{' '}
          , the decision was made to evaluate the risks and consult with the industry-specific
          experts. As a result of our in-depth research, we managed to find a bunch of firms and
          restaurants that were providing food delivery services. Nevertheless, there were a few
          more details for us to keep in mind:
        </p>

        <ul className={itemStyles.bullets}>
          <li>High cost of meals to order every day</li>

          <li>Restaurant menu — a lot of people wanted homemade lunches</li>

          <li>Many home cooks were ready to deliver their food</li>

          <li>Competitors' websites looked bad and outdated</li>

          <li>Many meals had no pictures making it harder to know what you are ordering</li>

          <li>
            Most of the time, users who use food delivery services are busy or hungry, so they need
            instant-gratification
          </li>
        </ul>
      </div>

      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Working process</h2>

        <p className={itemStyles.paragraph}>
          The first step in our to-do list was a{' '}
          <Link to={Routes.SERVICES_MVP_DEVELOPMENT} className={styles.link}>
            fully-functioning MVP development
          </Link>{' '}
          that allowed us to test the project's viability. Recently we published article about our
          preparatory measures for the{' '}
          <Link
            to={Routes.BLOG + 'how-to-not-fail-your-food-delivery-startup/'}
            className={styles.link}
          >
            MVP stage of food delivery startup
          </Link>
          .
        </p>

        <p className={itemStyles.paragraph}>
          To end up with a smoothly working MVP designed to showcase Rodion's idea, we started with
          the requirements elicitation. A simple website that we could use as a landing page to
          order a lunch delivery from certain cooks was all we ever needed at this phase. So the
          first version was done in just 3 days.
        </p>

        <p className={itemStyles.paragraph}>
          Basically, the interface was comprised of two key functionality elements:
        </p>

        <ul className={itemStyles.bullets}>
          <li>Available lunch display</li>

          <li>Lunch order</li>
        </ul>

        <h3 className={itemStyles.subtitle}>Prototyping</h3>

        <p className={itemStyles.paragraph}>
          After a successful MVP launch, we decided to develop the next version of the product.
        </p>

        <p className={itemStyles.paragraph}>
          Our primary goal here was to think over all the crucial details, so we started our work by
          prototyping the idea. The tool we used for prototyping was{' '}
          <a href="https://gomockingbird.com/home" target="__blank" className={styles.link}>
            Mockingbird
          </a>
          . At the first go-of, we came up with a basic set of features for both web and mobile
          versions, since such an approach is a common practice for similar projects.
        </p>

        <p className={itemStyles.paragraph}>
          Here are some of the key features and components the project had at the initial stage,
          including 4 types of users.
        </p>

        <div className={styles.lists}>
          <div className={styles.list}>
            <h4 className={styles.listTitle}>Cooks</h4>
            <p>Registration and Authorization</p>
            <p>Add new lunches</p>
            <p>View the list of lunches ready for order</p>
            <p>View the list of orders</p>
            <p>View balance</p>
          </div>

          <div className={styles.list}>
            <h4 className={styles.listTitle}>Users</h4>
            <p>View the list of lunches ready for order</p>
            <p>Possibility to order lunches and pay for them</p>
            <p>Notification system</p>
            <p>Send feedback to cookery experts</p>
          </div>

          <div className={styles.list}>
            <h4 className={styles.listTitle}>Couriers</h4>
            <p>Registration and Authorization</p>
            <p>View the list of orders</p>
            <p>View balance</p>
          </div>

          <div className={styles.list}>
            <h4 className={styles.listTitle}>Admins</h4>
            <p>User management</p>
            <p>Lunch management</p>
            <p>Order management</p>
          </div>
        </div>

        <p className={itemStyles.paragraph}>
          All the functionality was based on user stories, which were split into tasks right after.
          We used Trello to effectively track tasks and manage our workflow.
        </p>
      </div>

      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title} style={{ marginTop: '-100px' }}>
          Our team
        </h2>

        <p className={itemStyles.paragraph}>
          To exceed the client’s expectations, we assembled a proactive team of on-site seasoned
          professionals with years of software development experience. Our specialists examined the
          ins and outs of the current business issue to let people stay on top of their mental
          health.
        </p>

        <p className={itemStyles.paragraph}>
          They demonstrated their ability to meet tight deadlines while responding to the
          ever-changing business requirements without sacrificing the product quality. The team was
          comprised of:
        </p>

        <Team
          color="#5BAB08"
          list={[
            { count: 1, title: 'Frontend\ndeveloper' },
            { count: 1, title: 'Backend\ndeveloper' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'Project\nmanager' },
          ]}
        />

        <Resume
          services={['product', 'mvp', 'webDev', 'devOps']}
          technology={['react', 'rails', 'figma']}
        />
      </div>

      <Blockquote
        img={data.rodionSalnik.childImageSharp.fluid}
        text={`"Why spend extra money and time on ordering food to your doorstep? This is exactly what I was asking myself before I came up with an idea to create a tech-enabled lunch delivery solution for Brocoders. Appetini is a side service that allows users to get high-quality and healthy food delivered from home cooks and restaurants right to their office or home."`}
        author={
          <React.Fragment>
            <b>Rodion Salnik</b>,
            <br />
            CEO & Co-Founder at Brocoders
          </React.Fragment>
        }
        link={{ to: 'https://www.brocoders.com/', title: 'brocoders.com' }}
      />

      <div className={itemStyles.inner}>
        <h3 className={itemStyles.subtitle}>Design</h3>

        <p className={itemStyles.paragraph}>
          On top of everything else, the major challenge was to develop a fully-fledged food
          delivery platform anchored by an eye-pleasing design and a user-friendly interface. It
          means we had to ensure all the elements were intuitive enough so that every user could
          clearly understand where to look or how to make an order. Eventually, we ended up with a
          few design patterns to apply. The tool we used for designing was Figma.
        </p>

        <p className={itemStyles.paragraph}>
          Once the designs and prototypes were completed, we moved on to the development stage.
        </p>
      </div>

      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.subtitle}>Functionality expanding</h2>

        <p className={itemStyles.paragraph}>
          To meet the ever-growing market requirements and satisfy our own needs, the team decided
          to implement additional functionality enhancements.
        </p>

        <p className={itemStyles.paragraph}>
          Ordering lunches from different cooks. Previously, it was possible to order food delivery
          from only one cookery expert at a time. That said, we added the possibility to combine
          different cooks in one group so that a courier could visit each cook and take an order to
          the address Making group orders. We added the possibility to share the cart's link with
          every teammate so that the entire office could order food delivery from a single account
          in one payment Multiple lunch creation by templates Purchasing multiple deliveries at once
          to save money
        </p>

        <p className={itemStyles.paragraph}>
          This time around, we opted for the iterative development methodology to allow for easier
          testing and debugging, as well as to enable the seamless introduction of functionality
          changes along the way. Based on this model, each iterative change passed through the
          following steps:
        </p>

        <ul className={itemStyles.bullets}>
          <li>User stories</li>
          <li>Task decomposition</li>
          <li>Prototype drawing</li>
          <li>Additional design</li>
          <li>Development</li>
          <li>Testing</li>
        </ul>
      </div>

      <Img fluid={data.screen4.childImageSharp.fluid} className={itemStyles.container} alt="" />

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Business Challenges</h2>

        <p className={itemStyles.paragraph}>
          During the project development, we had to think over the functionality of saving orders in
          a cookie file that can later be shared with others. In such a case, if someone opened the
          link from another device, he would see the order that had already been formed and could
          add his own lunch to the list. Our developers faced a few pitfalls to work on, but their
          combined efforts resulted in an appropriate workaround.
        </p>

        <h2 className={itemStyles.title}>Delivered Solution</h2>

        <p className={itemStyles.paragraph}>
          Our platform leverages a modern, future-proof tech stack and features all the required
          tools to quickly browse the restaurant's menu and order lunch with just a few clicks.
        </p>

        <p className={itemStyles.paragraph}>With Appetini, you can:</p>

        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.icon1} />
            <p className={itemStyles.paragraph}>
              Order food from various cooks and restaurants using one service
            </p>
          </div>

          <div className={styles.card}>
            <div className={styles.icon2} />
            <p className={itemStyles.paragraph}>Get your food delivered just at dinner time</p>
          </div>

          <div className={styles.card}>
            <div className={styles.icon3} />
            <p className={itemStyles.paragraph}>
              Choose from different price ranges and save your time and money
            </p>
          </div>
        </div>

        <p className={itemStyles.paragraph}>
          Additionally, the resulting solution comprises three user types, each with its own
          functionality features.
        </p>

        <div className={styles.lists}>
          <div className={styles.list}>
            <h4 className={styles.listTitle}>Cooks and restaurants can</h4>
            <p>Place lunches</p>
            <p>View the list of their orders and mark those that are ready</p>
            <p>Check balance</p>
            <p>Deposit money into a cash or card account</p>
          </div>

          <div className={styles.list}>
            <h4 className={styles.listTitle}>Users can</h4>
            <p>
              View available lunches for tonight and tomorrow from different cooks and restaurants
            </p>
            <p>
              Order lunches from different cooks and restaurants with as many servings as needed
            </p>
            <p>
              Select either a card payment (LiqPay system was integrated) or a cash payment method
            </p>
            <p>Create team orders from one account</p>
          </div>

          <div className={styles.list}>
            <h4 className={styles.listTitle}>Couriers can</h4>
            <p>View the list of orders and mark those are delivered and paid</p>
            <p>View balance</p>
          </div>

          <div className={styles.list}>
            <h4 className={styles.listTitle}>Admins can</h4>
            <p>Adjust user roles (a cook, a customer or a courier)</p>
            <p>Manage each user type (deletion, editing)</p>
            <p>Manage dishes (adding photos, editing)</p>
            <p>Manage dish categories</p>
            <p>Manage payment methods</p>
            <p>Manage orders</p>
          </div>
        </div>
      </div>

      <Img fluid={data.screen5.childImageSharp.fluid} className={itemStyles.container} alt="" />

      <div className={clsx(itemStyles.lastItem, itemStyles.inner)}>
        <h2 className={itemStyles.title}>The results</h2>

        <p className={itemStyles.paragraph}>
          With social distancing becoming the new buzzword, an Appetini-like platform is right what
          any business should stick to nowadays. Our plans included the implementation of such new
          functionality enhancements as menu planner and SMS order confirmation without calling an
          operator. To date, the project is suspended for a while and is waiting in the wings.
        </p>
      </div>
    </Layout>
  );
};

export default AppetiniCase;
