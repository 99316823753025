import * as React from 'react';
import { Link } from 'gatsby';
import Technology from 'components/technology';
import { Technologies } from 'components/Types';
import { makeGroup } from '../../helpers';
// styles
import clsx from 'clsx';
import itemStyles from '../item/cases.module.css';
import styles from './resume.module.css';

const Routes = require('../../../routes').Routes;

const SERVICES = {
  mvp: {
    title: 'MVP development',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  product: {
    title: 'Product development',
    to: Routes.SERVICES_PRODUCT_DEVELOPMENT,
  },
  dedicatedTeam: {
    title: 'Dedicated team',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  digital: {
    title: 'Digital transformation',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
  mobileDev: {
    title: 'Mobile app development',
    to: Routes.SERVICES_MOBILE_APP_DEVELOPMENT,
  },
  webDev: {
    title: 'Web app development',
    to: Routes.SERVICES_WEB_APP_DEVELOPMENT,
  },
  devOps: {
    title: 'DevOps and Cloud Services',
    to: Routes.SERVICES_DEVOPS,
  },
};

interface Props {
  technology?: ReadonlyArray<Technologies>;
  services: ReadonlyArray<keyof typeof SERVICES>;
}

function Resume({ technology, services }: Props) {
  const t = technology && React.useMemo(() => makeGroup(technology, 2), [technology]);

  return (
    <div className={clsx(styles.container, itemStyles.container)}>
      <div className={styles.column}>
        <h3 className={styles.title}>Our Services</h3>
        <ul>
          {services.map((e) => (
            <li key={e}>
              <Link className={styles.link} to={SERVICES[e].to}>
                {SERVICES[e].title}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {t && (
        <div className={styles.column}>
          <h3 className={styles.title}>Technology</h3>
          {t.map((e, i) => (
            <Technology key={i.toString()} list={e} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Resume;
