import * as React from 'react';
import Img from 'gatsby-image';
import clsx from 'clsx';
import { ImageFluid } from 'components/Types';
import styles from './bq.module.css';
import itemStyles from '../item/cases.module.css';
import StarsIcon from '../../../../images/career/stars.inline.svg';

interface Props {
  text: string;
  author: React.ReactNode;
  img?: ImageFluid;
  innerClassName?: string;
  blockquoteClassName?: string;
  downloadReviewLink?: string;
  link?: {
    to: string;
    title: string;
    style?: string;
    target?: string;
  };
  linkText?: string;
  isRating?: boolean;
}

function Blockquote({
  img,
  text,
  author,
  link,
  innerClassName,
  blockquoteClassName,
  downloadReviewLink,
  isRating = false,
  linkText = 'Download full review',
}: Props) {
  return (
    <section className={clsx(styles.container, itemStyles.container, blockquoteClassName)}>
      <div className={clsx('inner-container', innerClassName)}>
        <blockquote className={styles.blockquote}>
          <figure className={styles.figure}>
            {img && <Img fluid={img} className={styles.ava} alt="" />}
            <div>
              {isRating && (
                <div className={styles.rating}>
                  <span className={styles.ratingNumber}>5.0</span>
                  <StarsIcon style={{ height: '18px', width: 'auto' }} />
                </div>
              )}
              <p className={styles.text}>{text}</p>
              {downloadReviewLink && (
                <a
                  target="_blank"
                  href={downloadReviewLink}
                  className={clsx(styles.link, styles.downloadLink)}
                  rel="noopener noreferrer"
                >
                  {linkText}
                </a>
              )}
              <figcaption className={styles.figcaption}>{author}</figcaption>
              {link ? (
                <a
                  href={link.to}
                  className={clsx(link.style, styles.link)}
                  rel="noopener noreferrer"
                  target={link.target ? link.target : '_self'}
                >
                  {link.title}
                </a>
              ) : null}
            </div>
          </figure>
        </blockquote>
      </div>
    </section>
  );
}

export default Blockquote;
